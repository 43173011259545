<template>
  <div>
    <v-card>
      <v-card-title>Onboarding - Add Company. You must be verified to see this page.</v-card-title>
      <v-card-text>
        <v-row class="mb-1">
          <v-col cols="12" sm="6">
            <v-text-field
              label="Company Name*"
              required
              outlined
              dense
              v-model="project.name"
              persistent-hint
              hint="Please enter your full company name!"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Company Website*"
              required
              outlined
              dense
              v-model="project.website"
              hide-details="auto"
              persistent-hint
              hint="Please enter your company website address"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              label="Company Type*"
              :items="types"
              v-model="project.type"
              required
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="Logo URI"
              required
              outlined
              dense
              v-model="project.logo"
              hide-details="auto"
              persistent-hint
              hint="We will display your logo in the marketplace. If you do not specify a logo, we will use our"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              label="Company Description"
              v-model="project.description"
              required
              outlined
              dense
              hide-details="auto"
            ></v-textarea>
          </v-col>
        </v-row>

        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" :disabled="!canSave" @click="createProject">Create Company</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    types: ['NFT', 'DEFI', 'P2E', 'Metaverse', 'Other'],
    project: {
      name: null,
      description: null,
      website: null,
      type: null,
      logo: null,
    },
  }),
  computed: {
    canSave() {
      return this.project.name && this.project.website && this.project.type
    },
  },
  methods: {
    createProject() {
      if (this.loading) return

      this.loading = true

      this.$http
        .post('/project', this.project)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Project successfully saved!`,
              },
              { root: true },
            )

            this.$router.push({ name: 'home' })
          }
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
